function setCameraBinding(element) {
  document.getElementById(element).setAttribute('set_bind', 'true');
}

function resetViewpoint(x) {
  document.getElementById('x3d').runtime.resetView();
  var orthoViewpoints = document.getElementById("x3d").getElementsByTagName("OrthoViewpoint");
  for (var i=0; i<orthoViewpoints.length; i++){
    if (document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointOben'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: 0, y: (x), z: 0});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.5), (-x*0.5), (x*0.5), (x*0.5)]);

    }else if(document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointLinks'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: -x, y: 0, z: 0});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.5), (-x*0.5), (x*0.5), (x*0.5)]);

    }else if(document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointFront'){
        document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: 0, y: 0, z: x});
        document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.5), (-x*0.5), (x*0.5), (x*0.5)]);
    }else{
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: -x*0.7, y: 0.4*x, z: x});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.5), (-x*0.5), (x*0.5), (x*0.5)]);
      // document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*1.3), (-y*4/5), (x), (y*2)]);
    }
  }
}
