aeussereEinwirkungenDB = {

    aeussereEinwirkungen_de: [{
        id: 1,
        name: "Einfeldträger",
        disabled: false
    }, {
        id: 2,
        name: "Bemessungsschnittgröße",
        disabled: true
    }, {
        id: 3,
        name: "Ohne Bemessung",
        disabled: true
    }]

}