einschraubtiefenDB = {

    einschraubtiefen_de: [

        {
            name: "Mittig",
            value: 0,
            disabled: true
        }, {
            name: "Optimieren",
            value: 1,
            disabled: true
        }, {
            name: "Definiert",
            value: 2,
            disabled: false
        }

    ]

}