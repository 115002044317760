schraubenabstandDB = {

    schraubenabstand_de: [

        {
            name: "Optimiert",
            value: 0,
            disabled: false
        }, {
            name: "Definiert",
            value: 1,
            disabled: true
        }

    ]

}