einschraubrichtungDB = {

    einschraubrichtung_de: [

        {
            name: "Verstärkung -> Bestand",
            value: true
        }, {
            name: "Bestand -> Verstärkung",
            value: false
        }

    ]

}