einschraubwinkelDB = {

    einschraubwinkel_de: [

        {
            name: "45°",
            value: 45,
            disabled: false
        }, {
            name: "90°",
            value: 90,
            disabled: false
        }, {
            name: "Definiert",
            value: 2,
            disabled: true
        }

    ]

}